import React from 'react';
import Styled from './Card.style';
import { ChartBars, ChartBarNegative, ChartMultiBars } from '../Charts';
import { ChartData } from '../../interfaces';
import Typography from '../Typography';
import { useTranslation, Trans } from 'react-i18next';
import { Popup } from '@savewatersavemoney/swsm-ui';
import * as _ from 'lodash';

interface CardProps {
  id: number;
  title: string;
  subTitle?: string;
  rows: Array<any>;
  type: string;
  theme?: string | undefined;
  chartData: ChartData;
  desc: string;
  style?: React.CSSProperties;
  isDesktop: boolean;
  displayMoney?: string;
  defaultLanguage: string;
}

const Card: React.FC<CardProps> = props => {
  const { title, subTitle, theme, id, type, chartData, isDesktop, displayMoney, defaultLanguage, desc } = props;

  const [t] = useTranslation('common');

  const renderRows = () => {
    switch (type) {
      case 'chart-bar':
        return (
          <ChartBars
            id={id}
            key={id}
            theme={theme}
            displayMoney={displayMoney}
            chartData={chartData && chartData}
            defaultLanguage={defaultLanguage}
          />
        );
      case 'chart-multi-bar':
        return (
          <ChartMultiBars
            id={id}
            key={id}
            theme={theme}
            chartData={chartData && chartData}
            defaultLanguage={defaultLanguage}
          />
        );
      case 'chart-bar-negative':
        return (
          <ChartBarNegative
            id={id}
            key={id}
            displayMoney={displayMoney}
            theme={theme}
            chartData={chartData && chartData}
          />
        );
      default:
        return <div key={id}>{type} type don't exist</div>;
    }
  };
  const text_translation_title = t(title);
  const text_translation_subTitle = subTitle && t(subTitle);
  const [showPopup, setPopup] = React.useState(false);
  return (
    <Styled.Card className="card" isDesktop={isDesktop} data-test="Card" id={`card-${id}`}>
      <Typography
        size="4"
        weight={600}
        style={{ textAlign: 'center', fontSize: '20px', marginBottom: subTitle ? '0px' : '32px' }}
        text={text_translation_title}
        data-test="title"
      />
      {subTitle && (
        <Typography
          size="p"
          style={{ textAlign: 'center', fontSize: '18px', marginBottom: '32px' }}
          weight={400}
          text={text_translation_subTitle}
        />
      )}
      {desc && desc.length > 0 ? <Styled.Info onClick={() => setPopup(!showPopup)}>i</Styled.Info> : null}

      <Popup
        style={{ width: isDesktop ? '700px' : '100%' }}
        open={showPopup}
        handleClick={() => {
          setPopup(!showPopup);
        }}
        isDesktop={isDesktop}
      >
        {desc &&
          _.map(desc, (el, index) => {
            return (
              <Styled.Desc key={index}>
                <Trans i18nKey={el} components={{ 1: <br /> }} />
              </Styled.Desc>
            );
          })}
      </Popup>

      {renderRows()}
    </Styled.Card>
  );
};

export default Card;
