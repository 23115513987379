import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';

const typographyStyle = css`
  color: #0b2641;
  font-weight: ${props => props.weight};
  line-height: 1.3;
  margin-bottom: 8px;
  margin-top: 0px;
  text-transform: none;
  font-family: 'Open Sans', sans-serif;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

const IconWrap = styled.div`
  position: relative;
  margin-right: 16px;
`;

const TextWrap = styled.div``;

const IconText = styled.div`
  font-size: 16px;
  text-transform: capitalize;
  color: '##b9b9b9';
`;
const IconSubText = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const HeadOneWrap = styled.div`
  overflow: hidden;
  text-align: center;
  position: relative;
  font-weight: 700;
`;

const HeadOne = styled.h1`
  ${typographyStyle}
  font-size: 40px;
  z-index: 6;
  display: inline-block;
  position: relative;
  ${props => {
    if (props.textAlign) {
      return css`
        text-align: ${props.textAlign};
      `;
    }
  }}
  @media screen and (max-width:700px) {
    font-size: 28px;
  }
`;

const HeadTwo = styled.h2`
  ${typographyStyle}
  font-size: 32px;
  ${props => {
    if (props.textAlign) {
      return css`
        text-align: ${props.textAlign};
      `;
    }
  }}
  @media screen and (max-width:700px) {
    font-size: 24px;
  }
`;

const QAHeadTwo = styled.h2`
  ${typographyStyle}
  letter-spacing: 0.9px;
  color: #656565;
  font-size: 16px;
  max-width: 400px;
  margin: 0 auto 8px auto;
  line-height: 1.4;
  white-space: pre-line;
  flex: 1 100%;
  font-weight: 400;
`;

const HeadThree = styled.h3`
  ${typographyStyle}
  font-size: 26px;
  ${props => {
    if (props.textAlign) {
      return css`
        text-align: ${props.textAlign};
      `;
    }
  }}

  @media screen and (max-width:700px) {
    font-size: 20px;
  }
`;
const HeadFour = styled.h4`
  ${typographyStyle}
  font-size: 20px;
  ${props => {
    if (props.textAlign) {
      return css`
        text-align: ${props.textAlign};
      `;
    }
  }}
  @media screen and (max-width:700px) {
    font-size: 18px;
  }
`;
const HeadFive = styled.h5`
  ${typographyStyle}
  font-size: 20px;
  ${props => {
    if (props.textAlign) {
      return css`
        text-align: ${props.textAlign};
      `;
    }
  }}
`;
const HeadSix = styled.h6`
  ${typographyStyle}
  font-size: 10px;
  ${props => {
    if (props.textAlign) {
      return css`
        text-align: ${props.textAlign};
      `;
    }
  }}
`;
const Paragraph = styled.p`
  ${typographyStyle}
  font-size: 16px;
  ${props => {
    if (props.textAlign) {
      return css`
        text-align: ${props.textAlign};
      `;
    }
  }}
`;
const SectionSeparator = styled.div`
  ${typographyStyle}
  font-size: 20px;
  font-weight: 600;
  color: ${colors.primary};
  border-bottom: 1px solid #dde5f3;
  margin-bottom: 20px;
  padding-bottom: 5px;
`;
export default {
  QAHeadTwo,
  HeadOne,
  HeadOneWrap,
  HeadTwo,
  HeadThree,
  HeadFour,
  HeadFive,
  HeadSix,
  Paragraph,
  Icon,
  IconWrap,
  TextWrap,
  IconText,
  IconSubText,
  SectionSeparator,
};
