import styled, { css } from 'styled-components';

const Card = styled.div`
  position: relative;
  background: white;
  margin-bottom: 8px;
  padding: 16px 8px;
  height: 100%;
  border: 4px solid #d7f1ff;
  border-radius: 10px;
  ${props => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
`;

const Title = styled.h4`
  color: #0b2641;
  font-size: 16px;
  margin: 0;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 32px;
`;
const desktopStyles = css`
  border: 4px solid #d7f1ff;
  padding: 16px;
  border-radius: 10px;
`;
const Info = styled.div`
  background: #d7f1ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 2px solid #5eb1de;
  cursor: pointer;
  top: 8px;
  right: 8px;
  font-family: 'Times New Roman';
  font-size: 20px;
`;
const Desc = styled.p`
  text-align: center;
`;
export default { Card, Title, Info, Desc };
