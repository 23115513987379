import { AdAnalyticsReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
const initialState: AdAnalyticsReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};
const adAnalyticsReducer = createReducer(initialState, {
  POST_ANALYTICS_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  POST_ANALYTICS_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },
  POST_ANALYTICS_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: action.payload.data.detail,
      done: false,
    };
  },
});
export default adAnalyticsReducer;
