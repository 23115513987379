import { UnifiedLabelReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
const initialState: UnifiedLabelReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};
const unifiedLabelReducer = createReducer(initialState, {
  GET_LABEL_DATA_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_LABEL_DATA_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },
  GET_LABEL_DATA_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: action.payload.data.detail,
      done: false,
    };
  },
});
export default unifiedLabelReducer;
