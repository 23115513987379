import styled from 'styled-components';
import { ButtonClean } from '@savewatersavemoney/swsm-ui';
import { colors } from '../../../assets/variables';
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: auto;
  .recharts-cartesian-axis-tick-value {
    tspan {
      font-size: 16px;
      fill: #0b2641;
      font-weight: 700;
    }
  }
  .recharts-cartesian-axis-line {
    stroke-width: 4px;
    stroke: black;
  }
  .yAxis .recharts-cartesian-axis-tick {
    display: none;
  }
  .recharts-label {
    font-weight: 700;
    fill: #0b2641;
  }
  .difference {
    opacity: 0.6;
  }
  .bar-movement {
    transform: translateY(-1px);
  }

  .original-values {
    opacity: 1;
    transform: translateY(-0.01px);
  }
`;

const VerticalLine = styled.div`
  width: 40px;
  height: 362px;
  background-color: #e7eaec;
  box-shadow: 4px 0 black;
  font-weight: 700;
  color: black;
  p {
    font-family: 'Open Sans', sans-serif;
  }
`;
const HorizontalLine = styled.div`
  width: 100%;
  height: 40px;
  background-color: #e7eaec;

  font-weight: 700;
  color: black;
  margin-top: -28px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonComp = styled(ButtonClean)`
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  color: #0b2641;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #25b3eb;
  padding: 8px 16px;
  &:last-child {
    border-bottom: 1px solid #25b3eb;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
    margin-top: 0;
  }
`;
const PieLegendDot = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 10px;
`;
const ToggleWrap = styled.div`
  height: 44px;
  border: 3px solid ${colors.primary};
  display: inline-flex;
  border-radius: 20px;
  align-items: center;
  overflow: hidden;
  width: 100%;
  margin-bottom: 16px;
  justify-content: space-around;
`;
const Item = styled.div`
  padding: 8px;
  width: 100%;
  font-size: 12px;
  cursor: pointer;
  text-align: center;

  &:first-child {
    border-right: 1px solid ${colors.primary};
  }
  &:last-child {
    border-left: 1px solid ${colors.primary};
  }
  &.active {
    background: ${colors.primary};
    color: white;
  }
  &.inactive {
    color: ${colors.primary};
    background: white;
  }
`;
export default { Wrap, VerticalLine, HorizontalLine, ButtonWrap, ButtonComp, PieLegendDot, ToggleWrap, Item };
