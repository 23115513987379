import Review from './Review';
import { connect } from 'react-redux';
import { AppState } from '../../interfaces';
import { setReview, viewReview } from '../../actions/review';
const mapStateToProps = (state: AppState) => ({
  review: state.reviewReducer,
});
const mapDispatchToProps = dispatch => ({
  setReview: data => dispatch(setReview(data)),
  viewReview: () => dispatch(viewReview()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Review);
