import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  .recharts-cartesian-axis-tick-value {
    tspan {
      font-size: 13px;
    }
  }
  .recharts-cartesian-axis-line {
    stroke-width: 4px;
    stroke: black;
  }
  .yAxis .recharts-cartesian-axis-tick {
    display: none;
  }
  .recharts-text {
    font-weight: 700;
    fill: #0b2641;
  }
  .recharts-reference-line {
    text {
      fill: white;
    }
  }
  .bar-negative {
    transform: translate(0, 15px);
  }
  .bar-positive {
    transform: translate(0, -15px);
  }
`;

const Totals = styled.div`
  border-top: 4px solid #25b3eb;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  h3 {
    margin: 16px 0 0;
    font-size: 26px;
    font-weight: 400;
    color: #174489;
  }
`;
export default { Wrap, Totals };
