import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';
export const setReview = data => {
  return {
    type: 'SET_REVIEW',
    payload: axios.post(`${getMicroServiceUrl('user-content')}review`, data),
  };
};

export const viewReview = () => {
  return {
    type: 'VIEW_REVIEW',
    payload: axios.get(`${getMicroServiceUrl('user-content')}user-has-review`),
  };
};
