import { AvailableRewardsReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: AvailableRewardsReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const availableRewardsReducer = createReducer(initialState, {
  GET_AVAILABLE_REWARDS_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_AVAILABLE_REWARDS_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },

  GET_AVAILABLE_REWARDS_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.data = action.payload.data;
  },
});

export default availableRewardsReducer;
