import { CompanyNameReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: CompanyNameReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const companyNameReducer = createReducer(initialState, {
  GET_COMPANY_DETAILS_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_COMPANY_DETAILS_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },

  GET_COMPANY_DETAILS_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.data = null;
  },
});

export default companyNameReducer;
