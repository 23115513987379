import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const getTotalRegistration = () => {
  return {
    type: 'GET_TOTAL_REGISTRATION',
    payload: axios.get(`${getMicroServiceUrl('reporting')}public/getTotalRegistration`),
  };
};

export const getTotalPotentialWaterSaving = () => {
  return {
    type: 'GET_POTENTIAL_WATER_SAVING',
    payload: axios.get(`${getMicroServiceUrl('reporting')}public/getTotalPotentialWaterSaving`),
  };
};

export const getTotalPotentialCarbonSaving = () => {
  return {
    type: 'GET_POTENTIAL_CARBON_SAVING',
    payload: axios.get(`${getMicroServiceUrl('reporting')}public/getTotalPotentialCarbonSaving`),
  };
};

export const getTotalPotentialMonetarySaving = () => {
  return {
    type: 'GET_POTENTIAL_MONEY_SAVING',
    payload: axios.get(`${getMicroServiceUrl('reporting')}public/getTotalPotentialMonetarySaving`),
  };
};
