import React from 'react';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import Styled from './Main.style';
import * as _ from 'lodash';
import Counter from '../../../components/Counter';
import { useTranslation } from 'react-i18next';
export interface SubSectionProps {
  isDesktop: any;
  registrations: number;
}

const SubSection: React.FC<SubSectionProps> = ({ isDesktop, registrations }) => {
  const items = [
    { value: 18871, type: 'water' },
    { value: 79.57, type: 'carbon', digits: 2 },
    { value: 104, type: 'money' },
  ];
  const [t] = useTranslation('common');
  const renderItems = () => {
    return _.map(items, (el, index) => {
      return (
        <Col key={index} md={4} xs={12} sm={12} style={{ height: '51px' }}>
          <Styled.SubSectionValue>
            <Counter digits={el.digits} type={el.type} end={el.value} start={0} timer={2} />
          </Styled.SubSectionValue>
        </Col>
      );
    });
  };
  return (
    <Styled.Wrapper isDesktop={isDesktop} style={{ background: 'white' }}>
      <Grid>
        <Row>
          <Col xs={12} sm={12} md={3}>
            <Styled.SubSectionItem className="border-item">
              <Row>
                <Col md={12} xs={12} sm={12} style={{ height: '51px' }}>
                  <Styled.SubSectionValue>
                    {registrations && <Counter type="none" end={registrations} start={0} timer={2} />}
                  </Styled.SubSectionValue>
                </Col>
              </Row>
              <p style={{ textAlign: 'center', color: '#0b2641', marginBottom: 0 }}>
                {' '}
                {t('portal.homepage.subsection.users')}
              </p>
            </Styled.SubSectionItem>
          </Col>
          <Col xs={12} sm={12} md={9}>
            <Styled.SubSectionItem>
              <Row>{renderItems()}</Row>
              <p style={{ textAlign: 'center', color: '#0b2641', marginBottom: 0 }}>
                {t('portal.homepage.subsection.average')}
              </p>
            </Styled.SubSectionItem>
          </Col>
        </Row>
      </Grid>
    </Styled.Wrapper>
  );
};

export default SubSection;
