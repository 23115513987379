import React, { useEffect } from 'react';
import ExternalLinks from '../../ExternalLinks';
import PageOverviewBanners from './PageOverviewBanners';
import PageOverviewBonusChallenges from './PageOverviewBonusChallenges';
import { Loader } from '@savewatersavemoney/swsm-ui';
import { BasePage } from '../../../interfaces';
import { FooterInfo } from './../../index';
import { Row, Col } from 'react-styled-flexboxgrid';
import RewardsBanner from '../../RewardsBanner';
import UnifiedLabel from '../../UnifiedLabel';
import Review from '../../Review';
import * as _ from 'lodash';

interface PageOverviewProps extends Omit<BasePage, 'page'> {
  challenges: any;
  clearFilters: Function;
  bonusChallenges: Object;
  getBonusChallenges: Function;
  generalData: any;
  getUnifiedLabel: Function;
  unifiedLabel: any;
}
const PageOverview: React.FC<PageOverviewProps> = props => {
  const {
    appWidth,
    getBonusChallenges,
    clearFilters,
    challenges,
    bonusChallenges,
    generalData,
    getUnifiedLabel,
    unifiedLabel,
  } = props;
  const isDesktop = appWidth > 800;

  useEffect(() => {
    if (!challenges.bonusChallengesStatus.done && !challenges.bonusChallengesStatus.loading) {
      getBonusChallenges();
    }
    getUnifiedLabel();
  }, [getBonusChallenges, clearFilters, challenges.bonusChallengesStatus, getUnifiedLabel]);

  const uwlEnabled = _.get(props, 'app.clientConfig.data.data.uwlaEnabled');
  const marketplaceEnabled = _.get(props, 'app.clientConfig.data.data.is_market_place_available');
  const videoLibrary = _.get(props, 'app.clientConfig.data.data.ask_me_videos_enabled');
  const clientSLug = _.get(props, 'app.clientConfig.data.data.water_client');
  const reviewEnabled = _.get(props, 'app.clientConfig.data.data.reviewEnabled');

  if (generalData.status.loading || challenges.bonusChallengesStatus.loading) {
    return (
      <div style={{ position: 'relative', height: '100px' }}>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <PageOverviewBonusChallenges isDesktop={isDesktop} challenges={challenges} bonusChallenges={bonusChallenges} />
      <RewardsBanner />
      <Row>
        {uwlEnabled && (
          <Col md={5} sm={12} xs={12}>
            <UnifiedLabel data={unifiedLabel} />
          </Col>
        )}
        {reviewEnabled && (
          <Col md={7} xs={12} sm={12}>
            <Review isDesktop={isDesktop} />
          </Col>
        )}
      </Row>
      <PageOverviewBanners isDesktop={isDesktop} marketplaceBanner={marketplaceEnabled} videoLibrary={videoLibrary} />

      <ExternalLinks />
      {clientSLug === 'nrwa' || clientSLug === 'awe' ? null : <FooterInfo slug={clientSLug} />}
    </>
  );
};
export default PageOverview;
