import { ReportingFiguresReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
const initialState: ReportingFiguresReducer = {
  getRegistration: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  getWaterSaving: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  getCarbonSaving: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  getMoneySaving: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};
const reportingFiguresReducer = createReducer(initialState, {
  GET_TOTAL_REGISTRATION_PENDING: state => {
    state.getRegistration.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_TOTAL_REGISTRATION_FULFILLED: (state, action) => {
    state.getRegistration.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getRegistration.data = action.payload.data;
  },
  GET_TOTAL_REGISTRATION_REJECTED: (state, action) => {
    state.getRegistration.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.getRegistration.data = action.payload.data;
  },
  GET_POTENTIAL_WATER_SAVING_PENDING: state => {
    state.getWaterSaving.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_POTENTIAL_WATER_SAVING_FULFILLED: (state, action) => {
    state.getWaterSaving.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getWaterSaving.data = action.payload.data;
  },
  GET_POTENTIAL_WATER_SAVING_REJECTED: (state, action) => {
    state.getWaterSaving.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.getWaterSaving.data = action.payload.data;
  },
  GET_POTENTIAL_CARBON_SAVING_PENDING: state => {
    state.getCarbonSaving.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_POTENTIAL_CARBON_SAVING_FULFILLED: (state, action) => {
    state.getCarbonSaving.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getCarbonSaving.data = action.payload.data;
  },
  GET_POTENTIAL_CARBON_SAVING_REJECTED: (state, action) => {
    state.getCarbonSaving.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.getCarbonSaving.data = action.payload.data;
  },
  GET_POTENTIAL_MONEY_SAVING_PENDING: state => {
    state.getMoneySaving.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_POTENTIAL_MONEY_SAVING_FULFILLED: (state, action) => {
    state.getMoneySaving.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getMoneySaving.data = action.payload.data;
  },
  GET_POTENTIAL_MONEY_SAVING_REJECTED: (state, action) => {
    state.getMoneySaving.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.getMoneySaving.data = action.payload.data;
  },
});
export default reportingFiguresReducer;
