import styled, { css } from 'styled-components';

const Card = styled.div`
  position: relative;
  background: white;
  margin-bottom: 16px;
  padding: 16px 8px;
  height: 100%;
  border: 4px solid #d7f1ff;
  border-radius: 10px;
  ${props => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
`;

const Title = styled.h4`
  color: #0b2641;
  font-size: 16px;
  margin: 0;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 16px;
`;
const desktopStyles = css`
  border: 4px solid #d7f1ff;
  padding: 24px 32px;
  border-radius: 10px;
`;
export default { Card, Title };
