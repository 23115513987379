import styled, { css } from 'styled-components';
const ImageWrap = styled.div`
  display: flex;
  flex: 1 0 auto;
  background-size: cover;
  margin: 0;
  height: 309px;
  background-position-x: center;
`;
const TextWrap = styled.div`
  padding: 2em;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
`;
const Title = styled.h4`
  font-size: 24px;
  min-height: 62px;
  margin: 0 0 10px;
`;
const Description = styled.p`
  font-size: 18px;
  min-height: auto;
  margin: 0 0 10px;
  line-height: 1.5;
  font-family: 'Open Sans', sans-serif;
`;
const OptButton = styled.button`
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  background: none;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: #25b3eb;
  text-decoration-thickness: 2px;
  min-height: 35px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin-top: 10px;
  text-transform: none;
  &:first-child {
    margin-right: 32px;
  }
`;
const ButtonWrap = styled.div`
  margin-bottom: 16px;
  display: flex;
`;
const Disclaimer = styled.p`
  font-size: 13px;
  margin: 0;
  line-height: 1.3;
`;
const desktopStyles = css`
  flex-direction: row;
  ${Title} {
    font-size: 26px;
    min-height: 62px;
    margin: 0 0 10px;
  }
  ${ImageWrap} {
    height: auto;
    min-height: 309px;
    margin: 0;
  }
  ${TextWrap} {
    flex-basis: 60%;
    padding: 4em 2em;
  }
  ${Description} {
    font-size: 18px;
    min-height: auto;
  }
`;
const BannerWrap = styled.div`
  display: flex;
  font-family: 'Open Sans', sans-serif;
  color: #0b2641;
  background-color: #d7f1ff;
  flex-direction: column;
  margin-bottom: 32px;
  border-radius: 10px;
  border: 2px solid #d7f1ff;
  overflow: hidden;
  min-height: 187px;
  @media screen and (min-width: 800px) {
    ${desktopStyles}
  }
`;
const SubmitWrap = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 4px solid #d7f1ff;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export default { BannerWrap, ButtonWrap, SubmitWrap, ImageWrap, TextWrap, Title, Description, OptButton, Disclaimer };
