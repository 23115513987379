import { ReviewReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
const initialState: ReviewReducer = {
  setReview: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  getReview: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};
const reviewReducer = createReducer(initialState, {
  SET_REVIEW_PENDING: state => {
    state.setReview.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  SET_REVIEW_FULFILLED: (state, action) => {
    state.setReview.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.setReview.data = action.payload.data;
  },
  SET_REVIEW_REJECTED: (state, action) => {
    state.setReview.status = {
      loading: false,
      error: action.payload.data.detail,
      done: false,
    };
  },
  VIEW_REVIEW_PENDING: state => {
    state.getReview.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  VIEW_REVIEW_FULFILLED: (state, action) => {
    state.getReview.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getReview.data = action.payload.data;
  },
  VIEW_REVIEW_REJECTED: (state, action) => {
    state.getReview.status = {
      loading: false,
      error: action.payload.data.detail,
      done: false,
    };
  },
});
export default reviewReducer;
