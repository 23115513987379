import { OptRewardsReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
const initialState: OptRewardsReducer = {
  optIn: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};
const optRewardsReducer = createReducer(initialState, {
  OPT_IN_REWARDS_PENDING: state => {
    state.optIn.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  OPT_IN_REWARDS_FULFILLED: (state, action) => {
    state.optIn.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.optIn.data = action.payload.data;
  },
  OPT_IN_REWARDS_REJECTED: (state, action) => {
    state.optIn.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.optIn.data = action.payload.data;
  },
});
export default optRewardsReducer;
