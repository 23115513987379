import PageOverview from './PageOverview';
import { AppState } from './../../../interfaces';
import { connect } from 'react-redux';
import { getPage } from '../../../actions';
import { getBonusChallenges, getExternalLinks } from '../../../actions/rewardProgram';
import { getUnifiedLabel } from '../../../actions/unifiedLabel';

const mapStateToProps = (state: AppState) => ({
  appWidth: state.uiReducer.appWidth,
  challenges: state.challengesReducer,
  externalLinks: state.externalLinksReducer,
  app: state.appReducer,
  smartMeterSignup: state.challengesReducer.smartMeterSignup,
  bonusChallenges: state.challengesReducer.bonusChallenges,
  generalData: state.appReducer.generalData,
  unifiedLabel: state.unifiedLabelReducer,
});

const mapDispatchToProps = dispatch => ({
  getPage: endpoint => dispatch(getPage(endpoint)),
  getExternalLinks: data => dispatch(getExternalLinks(data)),
  getBonusChallenges: () => dispatch(getBonusChallenges()),
  getUnifiedLabel: () => dispatch(getUnifiedLabel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageOverview);
